import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { donationService } from '../services/api';

const stripePromise = loadStripe('pk_test_51QKhrLAmlo2gNANaFz3Rlm10dhF99o3DavdNpf2BX2zOlQ7VOYn69Ph1dgS67RZbhZLDALZRo7zR05lAKi0m2Mbr00x2cTQMLj');

const DonationForm = ({ fundraiserId, fundraiserTitle, onSuccess, onError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [amount, setAmount] = useState('');
  const [message, setMessage] = useState('');
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    
    setIsProcessing(true);
    
    try {
      const { clientSecret } = await donationService.createPaymentIntent({
        amount: parseFloat(amount),
        fundraiserId,
        message
      });
      
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: 'Anonymous',
          },
        },
      });
      
      if (error) {
        onError(error.message);
      } else if (paymentIntent.status === 'succeeded') {
        // The webhook will handle donation record creation and fundraiser update
        onSuccess();
      }
    } catch (error) {
      onError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };
  
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-white mb-1">
          Donation Amount (USD)
        </label>
        <input
          type="number"
          min="1"
          step="0.01"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-black text-white"
          required
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-white mb-1">
          Message (optional)
        </label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-black text-white"
          rows="3"
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-white mb-1">
          Card Details
        </label>
        <div className="p-3 border border-gray-300 rounded-md bg-black text-white">
          <CardElement options={{
            style: {
              base: {
                color: 'white',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
            },
          }} />
        </div>
      </div>
      
      <button
        type="submit"
        disabled={isProcessing || !stripe}
        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isProcessing ? 'Processing...' : `Donate $${amount || '0'}`}
      </button>
    </form>
  );
};

const DonateModal = ({ isOpen, onClose, fundraiserId }) => {
  const handleDonateClick = () => {

alert('donate clicked')    
window.open(`https://buy.stripe.com/test_bIY5lU3ELdgb7zW289?client_reference_id=${fundraiserId}`, '_blank');
    onClose(); // Close the modal after opening payment in new tab
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-black text-white">
        <DialogHeader>
          <DialogTitle>Make a Donation</DialogTitle>
        </DialogHeader>
        <button
          onClick={handleDonateClick}
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md"
        >
          Proceed to Payment
        </button>
      </DialogContent>
    </Dialog>
  );
};

export default DonateModal;