import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fundraiserService, emailService, authService } from '../services/api';


import { 
 processMultipleFiles, 
 getImageSrc, 
 validateImage,
 revokeImagePreview,
 IMAGE_SETTINGS
} from '../utils/imageHandler';

function CreateFundraiser() {
 const navigate = useNavigate();
 const fileInputRef = useRef(null);
 const [formData, setFormData] = useState({
   title: '',
   description: '',
   goal: '',
   category: 'general',
   endDate: '',
   images: [],
   videoUrl: '',
   tags: [],
   status: 'active'
 });
 const [user, setUser] = useState()

 useEffect( ()=>{

  const auth = async () => {
    const { isAuthenticated, user } = await authService.checkAuth();
    setUser(user)
    console.log('user from create', user)
  }

  auth()

 }, [])
 
 const [loading, setLoading] = useState(false);
 const [error, setError] = useState(null);
 const [imagePreviewUrls, setImagePreviewUrls] = useState([]);
 const [uploadProgress, setUploadProgress] = useState(0);

 const handleChange = (e) => {
   const { name, value } = e.target;
   setFormData(prev => ({
     ...prev,
     [name]: value
   }));
 };

 const convertToBuffer = async (file) => {
   return new Promise((resolve, reject) => {
     const reader = new FileReader();
     reader.onload = () => {
       const buffer = Buffer.from(reader.result);
       resolve(buffer);
     };
     reader.onerror = reject;
     reader.readAsArrayBuffer(file);
   });
 };

 const handleImageSelect = async (e) => {
   const files = Array.from(e.target.files);
   setLoading(true);
   setError(null);
 
   try {
     const processedImages = await processMultipleFiles(files);
     const newPreviewUrls = files.map(file => URL.createObjectURL(file));
 
     setFormData(prev => ({
       ...prev,
       images: processedImages
     }));
     setImagePreviewUrls(newPreviewUrls);
 
   } catch (err) {
     setError(err.message || 'Error processing images');
   } finally {
     setLoading(false);
   }
 };
 
 const removeImage = (index) => {
   const newImages = [...formData.images];
   const newPreviewUrls = [...imagePreviewUrls];
   revokeImagePreview(newPreviewUrls[index]);
   newImages.splice(index, 1);
   newPreviewUrls.splice(index, 1);
   setFormData(prev => ({
     ...prev,
     images: newImages
   }));
   setImagePreviewUrls(newPreviewUrls);
 };

//  const handleSubmit = async (e) => {
//    e.preventDefault();
//    setLoading(true);
//    setError(null);

//    try {
//      if (parseFloat(formData.goal) <= 0) {
//        throw new Error('Goal amount must be greater than 0');
//      }

//      const tags = typeof formData.tags === 'string' 
//        ? formData.tags.split(',').map(tag => tag.trim()).filter(Boolean)
//        : formData.tags;

//      const finalFormData = {
//        ...formData,
//        goal: parseFloat(formData.goal),
//        email: user.email,
//        approved: false,
//        tags,
//        images: formData.images.map(img => ({
//          data: img.data,
//          contentType: img.contentType,
//          size: img.size
//        })),
      
//      };

//      if (!finalFormData.videoUrl) {
//        delete finalFormData.videoUrl;
//      }

//      await fundraiserService.create(finalFormData);
//      imagePreviewUrls.forEach(url => URL.revokeObjectURL(url));

//      const emailData = {
//       // recipientEmail: 'mtmusicandart@gmail.com',
//       recipientEmail: 'mtmusicandart@gmail.com',
//       fundraiserTitle: `Sigma Chi Clash Against Cancer`,
//       eventLink: 'https://hustlefundraise.com/fundraisers/6739563e0dc5cb71207dc33e',
//     };

  
//     const sendAdminEmail = async () => {
//       try {
//         const response = await emailService.sendAdminEmail(emailData);
//         console.log('Test email sent:', response);  // Log the response for debugging
//       } catch (error) {
//         console.error('Error sending test email:', error);  // Log any error
//       }
//     };
     
//     const sendUserEmail = async () => {
//       try {
//         const response = await emailService.sendUserEmail(emailData);
//         console.log('Test email sent:', response);  // Log the response for debugging
//       } catch (error) {
//         console.error('Error sending test email:', error);  // Log any error
//       }
//     };


//      navigate('/dashboard');
//    } catch (error) {
//      console.error('Error creating fundraiser:', error);
//      setError(error.response?.data?.error || error.message || 'Failed to create fundraiser');
//    } finally {
//      setLoading(false);
//    }
//  };

const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);
  setError(null);

  try {
    if (parseFloat(formData.goal) <= 0) {
      throw new Error('Goal amount must be greater than 0');
    }

    const tags = typeof formData.tags === 'string' 
      ? formData.tags.split(',').map(tag => tag.trim()).filter(Boolean)
      : formData.tags;

    const finalFormData = {
      ...formData,
      goal: parseFloat(formData.goal),
      email: user.email,
      approved: false,
      tags,
      images: formData.images.map(img => ({
        data: img.data,
        contentType: img.contentType,
        size: img.size
      }))
    };

    if (!finalFormData.videoUrl) {
      delete finalFormData.videoUrl;
    }

    // Create the fundraiser
    const createdFundraiser = await fundraiserService.create(finalFormData);
    
    // Clean up image previews
    imagePreviewUrls.forEach(url => URL.revokeObjectURL(url));

    // Prepare email data for both emails
    const baseEmailData = {
      fundraiserTitle: finalFormData.title,
      eventLink: `${window.location.origin}/fundraisers/${createdFundraiser._id}`,
    };

    // Send admin notification
    try {
      await emailService.sendAdminEmail({
        ...baseEmailData,
        recipientEmail: 'info@hustlefundraise.com',
      });
      console.log('Admin notification sent successfully');
    } catch (emailError) {
      console.error('Error sending admin notification:', emailError);
    }

    // Send user notification
    try {
      await emailService.sendUserEmail({
        ...baseEmailData,
        recipientEmail: user.email,
      });
      console.log('User notification sent successfully');
    } catch (emailError) {
      console.error('Error sending user notification:', emailError);
    }

    // Navigate to dashboard after everything is done
    navigate('/dashboard');
  } catch (error) {
    console.error('Error creating fundraiser:', error);
    setError(error.response?.data?.error || error.message || 'Failed to create fundraiser');
  } finally {
    setLoading(false);
  }
};

 useEffect(() => {
   return () => {
     imagePreviewUrls.forEach(url => revokeImagePreview(url));
   };
 }, [imagePreviewUrls]);

 return (
   <div className="max-w-2xl mx-auto p-6">
     <h1 className="text-2xl font-bold mb-6 text-white">Create Fundraiser</h1>
     
     {error && (
       <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
         {error}
       </div>
     )}
     
     <form onSubmit={handleSubmit} className="space-y-6">
       <div>
         <label className="block text-white mb-2" htmlFor="title">Title</label>
         <input
           id="title"
           type="text"
           name="title"
           value={formData.title}
           onChange={handleChange}
           className="w-full p-2 bg-black border-2 border-white rounded text-white focus:ring-2 focus:ring-blue-500"
           required
           minLength={5}
           maxLength={100}
         />
       </div>

       <div>
         <label className="block text-white mb-2" htmlFor="description">Description</label>
         <textarea
           id="description"
           name="description"
           value={formData.description}
           onChange={handleChange}
           className="w-full p-2 bg-black border-2 border-white rounded text-white focus:ring-2 focus:ring-blue-500"
           rows="4"
           required
           minLength={20}
           maxLength={1000}
         />
       </div>

       <div>
         <label className="block text-white mb-2" htmlFor="goal">Goal Amount ($)</label>
         <input
           id="goal"
           type="number"
           name="goal"
           value={formData.goal}
           onChange={handleChange}
           className="w-full p-2 bg-black border-2 border-white rounded text-white focus:ring-2 focus:ring-blue-500"
           min="1"
           step="0.01"
           required
         />
       </div>

       {/* ++++++++++ remming category for now  */}

       {/* <div>
         <label className="block text-white mb-2" htmlFor="category">Category</label>
         <select
           id="category"
           name="category"
           value={formData.category}
           onChange={handleChange}
           className="w-full p-2 bg-black border-2 border-white rounded text-white focus:ring-2 focus:ring-blue-500"
         >
           <option value="general">General</option>
           <option value="emergency">Emergency</option>
           <option value="education">Education</option>
           <option value="medical">Medical</option>
           <option value="memorial">Memorial</option>
           <option value="nonprofit">Nonprofit</option>
         </select>
       </div> */}

       <div>
         <label className="block text-white mb-2" htmlFor="endDate">End Date</label>
         <input
  id="endDate"
  type="date"
  name="endDate"
  value={formData.endDate}
  onChange={handleChange}
  className="w-full p-2 bg-black border-2 border-white rounded text-white focus:ring-2 focus:ring-blue-500 [color-scheme:dark]"
  min={new Date().toISOString().split('T')[0]}
  required
/>
       </div>

       <div className="space-y-4">
         <label className="block text-white">Images (up to 3)</label>
         <div className="flex flex-col gap-4">
           <div className="flex items-center justify-center w-full">
             <label className="w-full h-32 flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:bg-gray-50">
               <div className="flex flex-col items-center justify-center pt-5 pb-6">
                 <svg className="w-8 h-8 mb-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                   <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                 </svg>
                 <p className="mb-2 text-sm text-gray-500">
                   <span className="font-semibold">Click to upload</span> or drag and drop
                 </p>
                 <p className="text-xs text-gray-500">PNG, JPG, GIF (MAX. {IMAGE_SETTINGS.maxFileSize / (1024 * 1024)}MB)</p>
               </div>
               <input
                 ref={fileInputRef}
                 type="file"
                 className="hidden"
                 multiple
                 accept={IMAGE_SETTINGS.allowedTypes.join(',')}
                 onChange={handleImageSelect}
                 onClick={(e) => e.target.value = null}
               />
             </label>
           </div>

           {uploadProgress > 0 && (
             <div className="w-full bg-gray-200 rounded-full h-2.5">
               <div
                 className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                 style={{ width: `${uploadProgress}%` }}
               ></div>
             </div>
           )}

           {imagePreviewUrls.length > 0 && (
             <div className="grid grid-cols-3 gap-4">
               {imagePreviewUrls.map((url, index) => (
                 <div key={index} className="relative">
                   <img
                     src={url}
                     alt={`Preview ${index + 1}`}
                     className="w-full h-32 object-cover rounded-lg"
                   />
                   <button
                     type="button"
                     onClick={() => removeImage(index)}
                     className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                   >
                     <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                     </svg>
                   </button>
                 </div>
               ))}
             </div>
           )}
         </div>
       </div>

       <div>
         <label className="block text-white mb-2" htmlFor="videoUrl">
           YouTube Video URL (optional)
         </label>
         <input
           id="videoUrl"
           type="url"
           name="videoUrl"
           value={formData.videoUrl}
           onChange={handleChange}
           placeholder="https://www.youtube.com/watch?v=..."
           className="w-full p-2 bg-black border-2 border-white rounded text-white focus:ring-2 focus:ring-blue-500"
         />
         <p className="text-sm text-white mt-1">
           Enter a valid YouTube video URL (e.g., https://www.youtube.com/watch?v=xxxxx)
         </p>
       </div>

{/* +++++++++ remming tags for now */}

       {/* <div>
         <label className="block text-white mb-2" htmlFor="tags">Tags (optional)</label>
         <input
           id="tags"
           type="text"
           value={formData.tags.join(', ')}
           onChange={(e) => {
             const tagsArray = e.target.value.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
             setFormData(prev => ({
               ...prev,
               tags: tagsArray
             }));
           }}
           className="w-full p-2 bg-black border-2 border-white rounded text-white focus:ring-2 focus:ring-blue-500"
           placeholder="Enter tags separated by commas"
         />
         <p className="text-sm text-white mt-1">Separate tags with commas (e.g., charity, education, help)</p>
       </div> */}

       <div className="flex gap-4">
         <button
           type="submit"
           disabled={loading}
           className="flex-1 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed transition duration-150"
         >
           {loading ? 'Creating...' : 'Create Fundraiser'}
         </button>
         
         <button
           type="button"
           onClick={() => navigate('/dashboard')}
           className="px-4 py-2 border-2 border-white text-white rounded hover:bg-gray-800 transition duration-150"
         >
           Cancel
         </button>
       </div>
     </form>
   </div>
 );
}

export default CreateFundraiser;