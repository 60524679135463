import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { authService } from '../services/api';

const AdminLogin = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  
  const checkAuthStatus = useCallback(async () => {
    try {
      const { isAuthenticated, user } = await authService.checkAuth();
      
      if (isAuthenticated && user) {
        // Check localStorage first, then location state, then default to dashboard
        const returnUrl = localStorage.getItem('authReturnUrl') 
          || location.state?.from 
          || '/dashboard';
        
        // Clean up stored return URL
        localStorage.removeItem('authReturnUrl');
        
        // Navigate to the return URL
        navigate(returnUrl, { replace: true });
      }
    } catch (error) {
      console.error('Login auth check error:', error);
      setError('Failed to check authentication status');
    } finally {
      setIsLoading(false);
    }
  }, [navigate, location.state?.from]);


  useEffect(() => {
    // Check for various error conditions in URL parameters
    const authFailed = searchParams.get('authFailed');
    const errorMessage = searchParams.get('error');
    
    if (authFailed) {
      setError('Authentication failed. Please try again.');
      setIsLoading(false);
      return;
    }

    if (errorMessage) {
      setError(decodeURIComponent(errorMessage));
      setIsLoading(false);
      return;
    }

    // Check if we were redirected with an error state
    if (location.state?.error) {
      setError(location.state.error);
      setIsLoading(false);
      return;
    }

    checkAuthStatus();
  }, [checkAuthStatus, searchParams, location.state?.error]);



  const handleHubSpotLogin = async () => {
    setLoading(true);
    setError('');

    try {
      // Initiates HubSpot OAuth login
      authService.login();
      // Assumes successful authentication redirects to /admin/dashboard
    } catch (err) {
      setError('HubSpot login failed');
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <Card className="w-full max-w-md mx-4">
        <CardHeader>
          <CardTitle className="text-2xl text-center">Admin Login</CardTitle>
        </CardHeader>
        <CardContent>
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {error}
            </div>
          )}
          
          {/* Button to start HubSpot login */}
          <div className="space-y-4">
            <Button 
              onClick={handleHubSpotLogin} 
              className="w-full"
              disabled={loading}
            >
              {loading ? 'Redirecting to HubSpot...' : 'Login with HubSpot'}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default AdminLogin;
