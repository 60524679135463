// src/utils/imageHandler.js

// Constants for image settings
const IMAGE_SETTINGS = {
  maxWidth: 1200,
  maxHeight: 1200,
  quality: 80,
  maxFileSize: 5 * 1024 * 1024, // 5MB
  allowedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
  maxImages: 3
};

/**
 * Validates a file before processing
 * @param {File} file - The file to validate
 * @returns {Promise<Error|null>}
 */
const validateImage = async (file) => {
  if (!file) {
    throw new Error('No file provided');
  }

  if (!IMAGE_SETTINGS.allowedTypes.includes(file.type)) {
    throw new Error('Invalid file type. Only JPG, PNG, GIF and WebP are allowed');
  }

  if (file.size > IMAGE_SETTINGS.maxFileSize) {
    throw new Error(`File size must be less than ${IMAGE_SETTINGS.maxFileSize / (1024 * 1024)}MB`);
  }

  return null;
};

/**
 * Converts a File object to base64 string
 * @param {File} file - The file to convert
 * @returns {Promise<string>} Base64 string
 */
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
};

/**
 * Creates an image preview URL
 * @param {File} file - The file to preview
 * @returns {string} Preview URL
 */
const createImagePreview = (file) => {
  return URL.createObjectURL(file);
};

/**
 * Revokes an image preview URL to free memory
 * @param {string} previewUrl - The URL to revoke
 */
const revokeImagePreview = (previewUrl) => {
  if (previewUrl) {
    URL.revokeObjectURL(previewUrl);
  }
};

/**
 * Processes multiple files for upload
 * @param {FileList} files - The files to process
 * @returns {Promise<Array>} Array of processed image data
 */
const processMultipleFiles = async (files) => {
  const processedImages = [];
  const errors = [];
  
  const fileArray = Array.from(files);
  if (fileArray.length > IMAGE_SETTINGS.maxImages) {
    throw new Error(`Maximum ${IMAGE_SETTINGS.maxImages} images allowed`);
  }

  for (const file of fileArray) {
    try {
      await validateImage(file);
      const base64Data = await fileToBase64(file);
      
      processedImages.push({
        data: base64Data,
        contentType: file.type,
        originalName: file.name
      });
    } catch (error) {
      errors.push(`Error processing ${file.name}: ${error.message}`);
    }
  }

  if (errors.length > 0) {
    throw new Error(errors.join('\n'));
  }

  return processedImages;
};

/**
 * Converts a base64 string to a displayable image source
 * @param {Object} image - The image object containing data and contentType
 * @returns {string} Data URL for the image
 */
const getImageSrc = (image) => {
  if (!image || !image.data || !image.contentType) {
    return null;
  }

  // Check if data is already base64
  const base64Data = typeof image.data === 'string' 
    ? image.data 
    : Buffer.from(image.data).toString('base64');

  return `data:${image.contentType};base64,${base64Data}`;
};

/**
 * Helper function to format file size in bytes to human readable format
 * @param {number} bytes - The file size in bytes
 * @returns {string} Formatted file size
 */
const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

/**
 * Helper function to check if all required image utilities are available
 * @returns {boolean} True if all required APIs are available
 */
const checkImageSupport = () => {
  return !!(
    window.File &&
    window.FileReader &&
    window.FileList &&
    window.Blob &&
    window.URL &&
    window.URL.createObjectURL
  );
};

/**
 * Validates multiple images already in base64 format
 * @param {Array} images - Array of image objects with base64 data
 * @returns {boolean} True if all images are valid
 */
const validateExistingImages = (images) => {
  if (!Array.isArray(images)) {
    throw new Error('Images must be an array');
  }

  if (images.length > IMAGE_SETTINGS.maxImages) {
    throw new Error(`Maximum ${IMAGE_SETTINGS.maxImages} images allowed`);
  }

  return true;
};

// Export all functions and constants
export {
  IMAGE_SETTINGS,
  validateImage,
  fileToBase64,
  createImagePreview,
  revokeImagePreview,
  processMultipleFiles,
  getImageSrc,
  formatFileSize,
  checkImageSupport,
  validateExistingImages
};