import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fundraiserService } from '../services/api';
import { 
  processMultipleFiles, 
  getImageSrc, 
  validateImage,
  revokeImagePreview,
  IMAGE_SETTINGS
} from '../utils/imageHandler';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from "../components/ui/dialog";

function EditFundraiser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    goal: '',
    category: 'general',
    endDate: '',
    images: [],
    videoUrl: '',
    tags: [],
    status: 'active'
  });
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imagePreviewUrls, setImagePreviewUrls] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  // Fetch existing fundraiser data
  useEffect(() => {
    const fetchFundraiser = async () => {
      try {
        const fundraiser = await fundraiserService.getById(id);
        
        // Check if current user is the creator
        if (fundraiser.creator._id !== fundraiser.creator._id) {
          navigate('/fundraisers');
          return;
        }

        // Format date for the input field
        const formattedEndDate = fundraiser.endDate 
          ? new Date(fundraiser.endDate).toISOString().split('T')[0]
          : '';

        // Set form data with existing values
        setFormData({
          title: fundraiser.title,
          description: fundraiser.description,
          goal: fundraiser.goal,
          category: fundraiser.category,
          endDate: formattedEndDate,
          images: fundraiser.images || [],
          videoUrl: fundraiser.videoUrl || '',
          tags: fundraiser.tags || [],
          status: fundraiser.status
        });

        // Create preview URLs for existing images
        if (fundraiser.images && fundraiser.images.length > 0) {
          const previews = fundraiser.images.map(image => getImageSrc(image));
          setImagePreviewUrls(previews);
        }
      } catch (error) {
        console.error('Error fetching fundraiser:', error);
        setError('Failed to load fundraiser details');
      } finally {
        setLoading(false);
      }
    };

    fetchFundraiser();
  }, [id, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleImageSelect = async (e) => {
    const files = Array.from(e.target.files);
    setLoading(true);
    setError(null);
  
    try {
      // Process all files using our utility
      const processedImages = await processMultipleFiles(files);
      
      // Create preview URLs
      const newPreviewUrls = files.map(file => URL.createObjectURL(file));
  
      setFormData(prev => ({
        ...prev,
        images: [...prev.images, ...processedImages]
      }));
      setImagePreviewUrls(prev => [...prev, ...newPreviewUrls]);
  
    } catch (err) {
      setError(err.message || 'Error processing images');
    } finally {
      setLoading(false);
    }
  };
  
  const removeImage = (index) => {
    const newImages = [...formData.images];
    const newPreviewUrls = [...imagePreviewUrls];
    
    revokeImagePreview(newPreviewUrls[index]);
    
    newImages.splice(index, 1);
    newPreviewUrls.splice(index, 1);
    
    setFormData(prev => ({
      ...prev,
      images: newImages
    }));
    setImagePreviewUrls(newPreviewUrls);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (parseFloat(formData.goal) <= 0) {
        throw new Error('Goal amount must be greater than 0');
      }

      // Convert tags from string to array if needed
      const tags = typeof formData.tags === 'string' 
        ? formData.tags.split(',').map(tag => tag.trim()).filter(Boolean)
        : formData.tags;

      // Prepare the final form data
      const finalFormData = {
        ...formData,
        goal: parseFloat(formData.goal),
        approved: true,
        email: 'info@hustlefundraise.com',
        tags,
        images: formData.images.map(img => ({
          data: img.data,
          contentType: img.contentType,
          size: img.size
        }))
      };

      // Remove empty video URL
      if (!finalFormData.videoUrl) {
        delete finalFormData.videoUrl;
      }

      await fundraiserService.updateFundraiser(id, finalFormData);
      
      // Clean up preview URLs
      imagePreviewUrls.forEach(url => revokeImagePreview(url));
      
      navigate(`/fundraisers/${id}`);
    } catch (error) {
      console.error('Error updating fundraiser:', error);
      setError(error.response?.data?.error || error.message || 'Failed to update fundraiser');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    setError(null);
    
    try {
      await fundraiserService.delete(id);
      // Clean up preview URLs before navigating away
      imagePreviewUrls.forEach(url => revokeImagePreview(url));
      navigate('/fundraisers');
    } catch (error) {
      console.error('Error deleting fundraiser:', error);
      setError(error.response?.data?.error || error.message || 'Failed to delete fundraiser');
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      imagePreviewUrls.forEach(url => revokeImagePreview(url));
    };
  }, [imagePreviewUrls]);

  if (loading && !formData.title) {
    return <div className="text-center py-12">Loading...</div>;
  }

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">Edit Fundraiser</h1>
      
      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          {error}
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-gray-700 mb-2" htmlFor="title">Title</label>
          <input
            id="title"
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
            minLength={5}
            maxLength={100}
          />
        </div>

        <div>
          <label className="block text-gray-700 mb-2" htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            rows="4"
            required
            minLength={20}
            maxLength={1000}
          />
        </div>

        <div>
          <label className="block text-gray-700 mb-2" htmlFor="goal">Goal Amount ($)</label>
          <input
            id="goal"
            type="number"
            name="goal"
            value={formData.goal}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            min="1"
            step="0.01"
            required
          />
        </div>

        <div>
          <label className="block text-gray-700 mb-2" htmlFor="category">Category</label>
          <select
            id="category"
            name="category"
            value={formData.category}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="general">General</option>
            <option value="emergency">Emergency</option>
            <option value="education">Education</option>
            <option value="medical">Medical</option>
            <option value="memorial">Memorial</option>
            <option value="nonprofit">Nonprofit</option>
          </select>
        </div>

        <div>
          <label className="block text-gray-700 mb-2" htmlFor="endDate">End Date</label>
          <input
            id="endDate"
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            min={new Date().toISOString().split('T')[0]}
            required
          />
        </div>

        <div className="space-y-4">
          <label className="block text-gray-700">Images (up to 3)</label>
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-center w-full">
              <label className="w-full h-32 flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:bg-gray-50">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg className="w-8 h-8 mb-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                  </svg>
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="font-semibold">Click to upload</span> or drag and drop
                  </p>
                  <p className="text-xs text-gray-500">PNG, JPG, GIF (MAX. {IMAGE_SETTINGS.maxFileSize / (1024 * 1024)}MB)</p>
                </div>
                <input
                  ref={fileInputRef}
                  type="file"
                  className="hidden"
                  multiple
                  accept={IMAGE_SETTINGS.allowedTypes.join(',')}
                  onChange={handleImageSelect}
                  onClick={(e) => e.target.value = null}
                  disabled={formData.images.length >= 3}
                />
              </label>
            </div>

            {uploadProgress > 0 && (
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            )}

            {imagePreviewUrls.length > 0 && (
              <div className="grid grid-cols-3 gap-4">
                {imagePreviewUrls.map((url, index) => (
                  <div key={index} className="relative">
                    <img
                      src={url}
                      alt={`Preview ${index + 1}`}
                      className="w-full h-32 object-cover rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() => removeImage(index)}
                      className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                    >
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div>
          <label className="block text-gray-700 mb-2" htmlFor="videoUrl">
            YouTube Video URL (optional)
          </label>
          <input
            id="videoUrl"
            type="url"
            name="videoUrl"
            value={formData.videoUrl}
            onChange={handleChange}
            placeholder="https://www.youtube.com/watch?v=..."
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
          <p className="text-sm text-gray-500 mt-1">
            Enter a valid YouTube video URL (e.g., https://www.youtube.com/watch?v=xxxxx)
          </p>
        </div>
        <div>
          <label className="block text-gray-700 mb-2" htmlFor="tags">Tags (optional)</label>
          <input
            id="tags"
            type="text"
            value={formData.tags.join(', ')}
            onChange={(e) => {
              const tagsArray = e.target.value.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
              setFormData(prev => ({
                ...prev,
                tags: tagsArray
              }));
            }}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter tags separated by commas"
          />
          <p className="text-sm text-gray-500 mt-1">Separate tags with commas (e.g., charity, education, help)</p>
        </div>

        <div className="flex gap-4">
          <button
            type="submit"
            disabled={loading}
            className="flex-1 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed transition duration-150"
          >
            {loading ? 'Saving Changes...' : 'Save Changes'}
          </button>
          
          <button
            type="button"
            onClick={() => setShowDeleteDialog(true)}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-150"
          >
            Delete Fundraiser
          </button>
          
          <button
            type="button"
            onClick={() => navigate(`/fundraisers/${id}`)}
            className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-50 transition duration-150 text-white hover:text-black"
          >
            Cancel
          </button>
        </div>
      </form>

      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Fundraiser</DialogTitle>
            <DialogDescription>
              <div className='text-white'>Are you sure you want to delete this fundraiser? This action cannot be undone.</div>
              
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="gap-2">
            <button
              type="button"
              onClick={() => setShowDeleteDialog(false)}
              className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-50 transition duration-150 text-white"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleDelete}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-150"
            >
              Delete
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditFundraiser;