import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fundraiserService, authService, emailService } from '../services/api';
import { useAdmin } from '../contexts/AdminContext';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from "../components/ui/dialog";

function Dashboard() {
  const { isAdmin } = useAdmin();
  const [myFundraisers, setMyFundraisers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Existing dialog states
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedFundraiser, setSelectedFundraiser] = useState(null);
  const [completedFundraisers, setCompletedFundraisers] = useState([]);
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);
  const [showCompletionDialog, setShowCompletionDialog] = useState(false);

  // New end date dialog state
  const [showEndDateDialog, setShowEndDateDialog] = useState(false);
  const [endedFundraiser, setEndedFundraiser] = useState(null);

  const handleConfirmClick = (fundraiser) => {
    setSelectedFundraiser(fundraiser);
    setShowConfirmDialog(true);
  };

  const handleEndedClick = (fundraiser) => {
    if (fundraiser.currentAmount > 0) {
      setEndedFundraiser(fundraiser);
      setShowEndDateDialog(true);
    }
  };

  const isEnded = (endDate) => {
    return new Date(endDate) < new Date();
  };

  const handleConfirm = async (isAdmin) => {
    try {
      const updatedFundraiser = {
        ...selectedFundraiser,
        approved: true
      };

      await fundraiserService.updateFundraiser(selectedFundraiser._id, updatedFundraiser);
      
      const emailData = {
        recipientEmail: selectedFundraiser.email,
        fundraiserTitle: selectedFundraiser.title,
        eventLink: `${window.location.origin}/fundraisers/${selectedFundraiser._id}`,
      };

      try {
        await emailService.sendUserApprovalEmail(emailData);
      } catch (emailError) {
        console.error('Error sending approval email:', emailError);
      }

      const refreshedFundraisers = await fundraiserService.getAll();
      setMyFundraisers(Array.isArray(refreshedFundraisers) ? refreshedFundraisers : []);
      setShowConfirmDialog(false);
    } catch (error) {
      console.error('Error confirming fundraiser:', error);
      setError('Failed to confirm fundraiser');
    }
  };

  const handleCompletionDialogClose = () => {
    setShowCompletionDialog(false);
    if (currentNotificationIndex < completedFundraisers.length - 1) {
      setCurrentNotificationIndex(prev => prev + 1);
      setTimeout(() => setShowCompletionDialog(true), 300);
    }
  };

  useEffect(() => {
    const initializeDashboard = async () => {
      try {
        const { isAuthenticated, user } = await authService.checkAuth();
        
        if (!isAuthenticated) {
          navigate('/login', { state: { from: '/dashboard' }, replace: true });
          return;
        }

        setUser(user);

        const fundraisers = isAdmin 
          ? await fundraiserService.getAll() 
          : await fundraiserService.getMine();

        const validFundraisers = Array.isArray(fundraisers) ? fundraisers : [];
        setMyFundraisers(validFundraisers);

        if (!isAdmin) {
          const storedFundraisers = JSON.parse(localStorage.getItem('fundraiserStatus') || '{}');
          const newlyCompletedFundraisers = [];

          validFundraisers.forEach(fundraiser => {
            const fundingPercentage = (fundraiser.currentAmount / fundraiser.goal) * 100;
            const previouslyNotified = storedFundraisers[fundraiser._id]?.notified;
            
            if (fundingPercentage >= 100 && !previouslyNotified) {
              newlyCompletedFundraisers.push(fundraiser);
              storedFundraisers[fundraiser._id] = {
                notified: true,
                completedAt: new Date().toISOString()
              };
            }

            if (!storedFundraisers[fundraiser._id]) {
              storedFundraisers[fundraiser._id] = {
                notified: false
              };
            }
          });

          localStorage.setItem('fundraiserStatus', JSON.stringify(storedFundraisers));

          if (newlyCompletedFundraisers.length > 0) {
            setCompletedFundraisers(newlyCompletedFundraisers);
            setShowCompletionDialog(true);
          }
        }
        
      } catch (error) {
        console.error('Dashboard initialization error:', error);
        if (error.response?.status === 401 && !isAdmin) {
          navigate('/login', { state: { from: '/dashboard' }, replace: true });
          return;
        }
        setError('Failed to load dashboard data');
      } finally {
        setLoading(false);
      }
    };

    initializeDashboard();
  }, [navigate, isAdmin]);

  const handleCreateNew = () => {
    if (!user && !isAdmin) {
      navigate('/login', { state: { from: '/fundraisers/create' }, replace: true });
      return;
    }
    navigate('/fundraisers/create');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <div className="text-xl">Loading your dashboard...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-4">
          {error}
          <button 
            onClick={() => window.location.reload()} 
            className="ml-4 text-sm underline"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4">
      <div className="flex justify-between items-center mb-6">
        <div>
          {user && (
            <p className="text-white mt-1 text-xl">
              {isAdmin ? 'Admin' : user.username}'s fundraisers:
            </p>
          )}
        </div>
        <button
          onClick={handleCreateNew}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
        >
          <span className="min-[557px]:inline hidden">Create New Fundraiser</span>
          <span className="min-[557px]:hidden inline">New</span>
        </button>
      </div>

      {myFundraisers.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-white mb-4">You haven't created any fundraisers yet.</p>
          <button 
            onClick={handleCreateNew}
            className="text-blue-500 hover:underline"
          >
            Create your first fundraiser →
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {myFundraisers.map((fundraiser) => (
            <div key={fundraiser._id} className="bg-black rounded-lg shadow-md overflow-hidden border-2 border-white relative min-h-[300px]">
              <div className="p-6 flex flex-col h-full">
                <div className="flex flex-col items-center w-full mb-4">
                  <div>
                    {isAdmin && (
                      <div className="text-white text-center mb-2">
                        {fundraiser.approved ? 'Approved' : 'Pending'}
                      </div>
                    )}
                    <h2 className="text-xl font-semibold mb-2 text-white text-center">{fundraiser.title}</h2>
                    <p className="text-sm mb-2">
                      <span className='text-white'>
                        Created {new Date(fundraiser.createdAt).toLocaleDateString()} by {fundraiser.creator?.username || 'Anonymous'}
                      </span>
                    </p>
                    <p className="text-sm text-white">
                      Ends: {new Date(fundraiser.endDate).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                
                <p className="text-white mb-4 line-clamp-3 flex-grow">{fundraiser.description}</p>
                
                <div className="space-y-2">
                  <div className="text-center text-white text-sm -mt-2 font-bold italic">
                    {Math.round((fundraiser.currentAmount / fundraiser.goal) * 100) || 0}% Funded
                  </div>
                  <div className="w-3/4 mx-auto">
                    <div className="h-4 bg-black border border-white rounded-full overflow-hidden">
                      <div
                        className="h-full bg-green-500 border-r border-white rounded-full min-w-[2px]"
                        style={{
                          width: `${Math.min((fundraiser.currentAmount / fundraiser.goal) * 100, 100)}%`
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between text-sm mt-4">
                    <span className="text-white"><span className="font-bold">Raised:</span> ${(fundraiser.currentAmount || 0).toLocaleString()}</span>
                    <span className="text-white"><span className="font-bold">Goal:</span> ${(fundraiser.goal || 0).toLocaleString()}</span>
                  </div>
                </div>
                
                <div className="mt-3">
                  <div className="flex items-center justify-between">
                    {isAdmin && fundraiser.creator && (
                      <div className="text-left text-sm">
                        <div className="text-white">{fundraiser.creator.username}</div>
                      </div>
                    )}
                    
                    <div className="flex gap-2">
                      {isAdmin && !fundraiser.approved && (
                        <button
                          onClick={() => handleConfirmClick(fundraiser)}
                          className="text-white border-2 border-white border-solid px-4 py-2 rounded hover:bg-white hover:text-black transition-colors"
                        >
                          Confirm
                        </button>
                      )}
                      {isEnded(fundraiser.endDate) && (
                        <button
                          onClick={() => handleEndedClick(fundraiser)}
                          className="bg-yellow-500 text-black px-4 py-2 rounded font-semibold hover:bg-yellow-600 transition-colors"
                        >
                          Ended
                        </button>
                      )}
                      <Link
                        to={`/fundraisers/${fundraiser._id}`}
                        className="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
                      >
                        {isAdmin ? 'Details' : 'View Details'}
                      </Link>
                      {!isAdmin && !fundraiser.approved && (
                        <div className="text-right text-sm ml-28">
                          <div className="text-gray-400">Status:</div>
                          <div className="text-yellow-500">Pending</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Admin Confirmation Dialog */}
      <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Fundraiser</DialogTitle>
            <DialogDescription>
              Are you sure you want to approve this fundraiser?
              {selectedFundraiser && (
                <div className="mt-2">
                  <strong className="block text-white">Title:</strong>
                  <span className="text-gray-300">{selectedFundraiser.title}</span>
                  <strong className="block mt-2 text-white">Created by:</strong>
                  <span className="text-gray-300">{selectedFundraiser.creator?.username}</span>
                </div>
              )}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <div className="flex gap-2 justify-end">
              <button
                onClick={handleConfirm}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
              >
                Confirm
              </button>
              <button
                onClick={() => setShowConfirmDialog(false)}
                className="px-4 py-2 border border-gray-600 rounded text-white hover:bg-gray-800 transition-colors"
              >
                Cancel
              </button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Fundraiser Completion Dialog */}
      <Dialog open={showCompletionDialog} onOpenChange={handleCompletionDialogClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Congratulations! 🎉</DialogTitle>
            <DialogDescription>
              {completedFundraisers[currentNotificationIndex] && (
                <div className="mt-4 space-y-4">
                  <p>Your project "{completedFundraisers[currentNotificationIndex].title}" has been fully funded!</p>
                  <p>
                    Please contact the admin at{' '}
                    <a 
                      href="mailto:info@hustlefundraise.com"
                      className="text-blue-400 hover:text-blue-300"
                    >
                      info@hustlefundraise.com
                    </a>
                    {' '}for payout arrangements.
                  </p>
                  <p>Thank you for using our platform!</p>
                </div>
              )}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <button
              onClick={handleCompletionDialogClose}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              Close
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

{/* End Date Dialog */}
<Dialog open={showEndDateDialog} onOpenChange={setShowEndDateDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Fundraiser Ended</DialogTitle>
            <DialogDescription>
              {endedFundraiser && (
                <div className="mt-4 space-y-4">
                  <p>Your Fundraiser "{endedFundraiser.title}" has ended.</p>
                  <p>
                    Contact the admin at{' '}
                    <a 
                      href="mailto:info@hustlefundraise.com"
                      className="text-blue-400 hover:text-blue-300"
                    >
                      info@hustlefundraise.com
                    </a>
                    {' '}for payout arrangements.
                  </p>
                  <p>Thank you for using our platform!</p>
                </div>
              )}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <button
              onClick={() => setShowEndDateDialog(false)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              Close
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

    </div>
  );
}

export default Dashboard;