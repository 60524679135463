import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { donationService } from '../services/api';

const stripePromise = loadStripe('pk_test_51QKhrLAmlo2gNANaFz3Rlm10dhF99o3DavdNpf2BX2zOlQ7VOYn69Ph1dgS67RZbhZLDALZRo7zR05lAKi0m2Mbr00x2cTQMLj');

const DonationForm = ({ fundraiserId, fundraiserTitle, onSuccess, onError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [amount, setAmount] = useState('');
  const [message, setMessage] = useState('');

  const resetForm = () => {
    setAmount('');
    setMessage('');
    if (elements) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        cardElement.clear();
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      console.log('Stripe not initialized');
      return;
    }
  
    setIsProcessing(true);
  
    try {
      const requestBody = {
        amount: parseFloat(amount),
        fundraiserId,
        message
      };
  
      console.log('Sending payment intent request:', requestBody);
  
      const { clientSecret } = await donationService.createPaymentIntent(requestBody);
      console.log('Received client secret');

      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: 'Anonymous Donor',
          },
        },
      });
  
      console.log('Payment confirmation result:', paymentResult);

      if (paymentResult.error) {
        console.error('Payment error:', paymentResult.error);
        onError(paymentResult.error.message);
      } else if (paymentResult.paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded:', paymentResult.paymentIntent);
        onSuccess(amount);
        resetForm();
      } else {
        console.warn('Unexpected payment status:', paymentResult.paymentIntent.status);
        onError('Payment status unexpected: ' + paymentResult.paymentIntent.status);
      }
    } catch (error) {
      console.error('Payment processing error:', error);
      onError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-white mb-1">
          Donation Amount (USD)
        </label>
        <input
          type="number"
          min="1"
          step="0.01"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-black text-white"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-white mb-1">
          Message (optional)
        </label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-black text-white"
          rows="3"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-white mb-1">
          Card Details
        </label>
        <div className="p-3 border border-gray-300 rounded-md bg-black text-white" style={{ minWidth: '500px' }}>
          <CardElement
            options={{
              style: {
                base: {
                  color: 'white',
                  '::placeholder': {
                    color: '#aab7c4',
                    width: '1000px'
                  },
                  '& .StripeElement--invalid': {
                    color: 'red',
                  },
                  '& .StripeElement': {
                    padding: '10px 14px',
                  },
                },
              },
            }}
          />
        </div>
      </div>

      <button
        type="submit"
        disabled={isProcessing || !stripe}
        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isProcessing ? 'Processing...' : `Donate $${amount || '0'}`}
      </button>
    </form>
  );
};

export default DonationForm;