import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { Home, Search, LayoutDashboard } from 'lucide-react';
import { authService } from '../services/api';
import { useAdmin } from '../contexts/AdminContext';

import dolla from '../assets/dolla_transparent.png'

function Navbar() {
  const { isAdmin } = useAdmin();
 const [isAuthenticated, setIsAuthenticated] = useState(false);
 const [user, setUser] = useState(null);
 const [loading, setLoading] = useState(true);
 const navigate = useNavigate();
 const location = useLocation();

 const checkAuth = useCallback(async () => {
   try {
     const { isAuthenticated, user } = await authService.checkAuth();
     setIsAuthenticated(isAuthenticated);
     setUser(user);
   } catch (error) {
     console.error('Auth check error:', error);
     setIsAuthenticated(false);
     setUser(null);
   } finally {
     setLoading(false);
   }
 }, []);

 useEffect(() => {
   checkAuth();
   
   let interval;
   if (isAuthenticated) {
     interval = setInterval(checkAuth, 30000);
   }
   
   return () => {
     if (interval) {
       clearInterval(interval);
     }
   };
 }, [checkAuth, isAuthenticated]);

 const handleGoogleLogin = () => {
   localStorage.setItem('authReturnUrl', location.pathname);
   authService.login();
 };

 const handleLogout = async () => {
   try {
     await authService.logout();
     setIsAuthenticated(false);
     setUser(null);
     navigate('/login', { replace: true });
   } catch (error) {
     console.error('Logout error:', error);
   }
 };

 if (loading) {
   return (
<nav className="bg-black text-white shadow-lg sticky top-0">

       <div className="max-w-7xl mx-auto px-4">
         <div className="flex justify-between h-16">
           <div className="flex items-center">
             <span className="text-xl font-bold text-white sm:inline hidden">HU$TLE Fundraising</span>
             {/* <Home className="sm:hidden h-6 w-6 text-white" /> */}

           </div>
         </div>
       </div>
     </nav>
   );
 }

 return (
<nav className="bg-black text-white shadow-lg sticky top-0">

     <div className="max-w-7xl mx-auto px-4">
       <div className="flex justify-between h-16">
         <div className="flex items-center">
           <Link to="/" className="flex items-center">
             <span className="trajan text-xl font-bold min-[600px]:inline hidden hover:text-gray-600 hover:text-green-500">HU<span className='text-[#28923C]'>$</span>TLE</span>
             {/* <Home className="min-[600px]:hidden h-6 w-6 text-white" /> */}
             <img src={dolla} className="min-[600px]:hidden h-6 w-5 active:h-[2rem] active:w-[1.75rem] transition-transform duration-200"/>
           </Link>
           <div className="ml-10 flex items-center space-x-4">
             <Link 
               to="/fundraisers" 
               className="hover:text-gray-600 transition-colors flex items-center"
             >
               <span className="trajan min-[420px]:inline hidden hover:text-green-500">Fundraisers</span>
               <Search className="min-[420px]:hidden h-5 w-5 active:w-7 active:h-7" />
             </Link>
             {isAuthenticated && (
               <Link 
                 to="/dashboard" 
                 className="hover:text-gray-600 transition-colors flex items-center"
               >
                 <span className="min-[420px]:inline hidden hover:text-green-500"> <span className='trajan'>Dashboard</span></span>
                 <LayoutDashboard className="min-[420px]:hidden h-5 w-5" />
               </Link>
             )}
           </div>
         </div>
         <div className="flex items-center">
           {isAuthenticated ? (
             <div className="flex items-center space-x-4">
               <div className="flex items-center space-x-2">
                 {user?.avatar && (
                  <> 
                   <img 
                     src={user.avatar}
                     alt={user.username}
                     className={`ml-2 w-8 h-8 rounded-full ${isAdmin ? 'border-4 border-solid- border-green-500' : ''}`}
                   /></>
                 )}
                 <span className="trajan text-white min-[690px]:inline hidden">{user?.username}</span>
               </div>
               <button
                 onClick={handleLogout}
                 className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition-colors"
               >
                 Logout
               </button>
             </div>
           ) : (
             <button
               onClick={handleGoogleLogin}
               className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
             >
               Login/Register
             </button>
           )}
         </div>
       </div>
     </div>
   </nav>
 );
}

export default Navbar;