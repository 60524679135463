import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { authService } from '../services/api';
import { useAdmin } from '../contexts/AdminContext';

function ProtectedRoute({ children }) {
  const { isAdmin } = useAdmin();
  const [authState, setAuthState] = useState({
    isLoading: true,
    isAuthenticated: false,
    user: null,
    error: null
  });
  
  const location = useLocation();
  const navigate = useNavigate();

  const checkAuth = useCallback(async () => {
    // If admin is authenticated via context, we're done
    if (isAdmin) {
      setAuthState({
        isLoading: false,
        isAuthenticated: true,
        user: { role: 'admin' },
        error: null
      });
      return;
    }

    try {
      const { isAuthenticated, user } = await authService.checkAuth();
      
      setAuthState({
        isLoading: false,
        isAuthenticated,
        user,
        error: null
      });

      if (!isAuthenticated) {
        localStorage.setItem('authReturnUrl', location.pathname);
      }
    } catch (error) {
      console.error('Protected route auth check error:', error);
      setAuthState({
        isLoading: false,
        isAuthenticated: false,
        user: null,
        error: 'Authentication check failed'
      });
    }
  }, [location.pathname, isAdmin]);

  useEffect(() => {
    checkAuth();
    
    // Only set up polling if not admin
    let interval;
    if (!isAdmin) {
      interval = setInterval(checkAuth, 30000);
    }
    
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [checkAuth, isAdmin]);

  if (authState.isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center space-y-4">
          <div className="text-lg">Loading...</div>
          {authState.error && (
            <div className="text-red-600 text-sm">{authState.error}</div>
          )}
        </div>
      </div>
    );
  }

  if (!authState.isAuthenticated && !isAdmin) {
    const redirectState = {
      from: location.pathname,
      ...(authState.error && { error: authState.error })
    };

    return <Navigate to="/login" state={redirectState} replace />;
  }

  return (
    <div className="protected-route">
      {authState.error && (
        <div className="bg-yellow-50 text-yellow-800 px-4 py-2 text-sm">
          Warning: {authState.error}
        </div>
      )}
      {children}
    </div>
  );
}

export default ProtectedRoute;