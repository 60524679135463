import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fundraiserService, authService } from '../services/api';
import { getImageSrc } from '../utils/imageHandler';
import ImageCarousel from '../components/ImageCarousel';
import DonateModal from '../components/DonateModal';
import { useNavigate } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaLinkedin  } from 'react-icons/fa';
import { HiMail, HiLink, HiShare } from 'react-icons/hi';
import { emailService } from '../services/api';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogDescription,
} from "../components/ui/dialog";


import DonationForm from './DonationForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAdmin } from '../contexts/AdminContext';

// <-- test public key

// const stripePromise = loadStripe('pk_test_51QKQDdJy4t2Q3Gk05LGTYaDw3uaadv38MhLICzknf1mU10tYqaR5ne0DyIj1LyE8fPGeJjgLQcDQCrqSPp6dok9n00FmC8nqan');

// <-- live public key

const stripePromise = loadStripe('pk_live_51QKQDdJy4t2Q3Gk01rn4iq1RmBuUUSpNYbvI8P6g6z9IrJTS1pq97Md6BkAvPvOZPf91MhdUXXloNPlu0Y4Za3AV00LNiiUsfD');

const ShareButtons = ({ title, url }) => {
  const [copied, setCopied] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const shareText = `Check out this fundraising event by Hustlefundraise.com: ${title}`;
  
  const handleNativeShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: shareText,
          url: url
        });
        console.log('Shared successfully');
      } catch (err) {
        console.log('Error sharing:', err);
        // Fallback to dialog if native share fails
        setIsDialogOpen(true);
      }
    } else {
      setIsDialogOpen(true);
    }
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(`${shareText}\n${url}`);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.log('Error copying:', err);
    }
  };

  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(shareText)}`,
    twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(url)}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&summary=${encodeURIComponent(shareText)}`,
    email: `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(`${shareText}\n\n${url}`)}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&summary=${encodeURIComponent(shareText)}`
  };
  
  const shareOptions = [
    {
      icon: FaFacebook,
      label: 'Facebook',
      onClick: () => window.open(shareLinks.facebook, '_blank', 'noopener,noreferrer'),
      className: 'hover:text-[#1877F2]'
    },
    {
      icon: FaLinkedin,
      label: 'LinkedIn',
      onClick: () => window.open(shareLinks.linkedin, '_blank', 'noopener,noreferrer'),
      className: 'hover:text-[#0A66C2]'
    },
    {
      icon: FaTwitter,
      label: 'X (Twitter)',
      onClick: () => window.open(shareLinks.twitter, '_blank', 'noopener,noreferrer'),
      className: 'hover:text-gray-400'
    },
    {
      icon: FaLinkedin,
      label: 'LinkedIn',
      onClick: () => window.open(shareLinks.linkedin, '_blank', 'noopener,noreferrer'),
      className: 'hover:text-[#0A66C2]'
    },
    {
      icon: HiMail,
      label: 'Email',
      onClick: () => {
        const emailLink = document.createElement('a');
        emailLink.href = shareLinks.email;
        emailLink.target = '_blank';
        emailLink.rel = 'noopener noreferrer';
        emailLink.click();
      },
      className: 'hover:text-gray-400'
    },
    {
      icon: HiLink,
      label: copied ? 'Copied!' : 'Copy Link',
      onClick: handleCopyLink,
      className: 'hover:text-gray-400'
    }
  ];

  return (
    <>
      <button
        onClick={handleNativeShare}
        className="inline-flex items-center gap-2 bg-black text-white px-4 py-2 rounded border-2 border-white hover:bg-gray-900 transition-colors"
      >
        <HiShare size={20} />
        <span className="min-[360px]:inline hidden">Share</span>
      </button>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="w-72 p-4 bg-black border-2 border-white">
          <div className="grid gap-4">
            {shareOptions.map((option, index) => (
              <button
                key={index}
                onClick={() => {
                  option.onClick();
                  setIsDialogOpen(false);
                }}
                className={`flex items-center gap-3 w-full px-4 py-3 text-white rounded-lg hover:bg-gray-800 transition-colors ${option.className}`}
              >
                <option.icon size={20} />
                <span className="text-sm">{option.label}</span>
              </button>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

function FundraiserDetails() {
  const navigate = useNavigate();
  const [showDonateModal, setShowDonateModal] = useState(false);
  const { id } = useParams();
  const [fundraiser, setFundraiser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreator, setIsCreator] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [showThankYouDialog, setShowThankYouDialog] = useState(false);
  const [donationAmount, setDonationAmount] = useState(0);
  const { isAdmin } = useAdmin();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showPostActionDialog, setShowPostActionDialog] = useState(false);
  const [deletedFundraiserInfo, setDeletedFundraiserInfo] = useState(null);

  const isEnded = (endDate) => {
    return endDate && new Date(endDate) < new Date();
  };

  const handleDonationSuccess = async (amount, id, setFundraiser) => {
    console.log('Donation successful!', amount, id); // Log for debugging
    setDonationAmount(amount);
  
    try {
      // Update the fundraiser's current amount
      await fundraiserService.updateFundraiserAmount(id, amount);
  
      // Refetch the fundraiser data to update the UI
      const data = await fundraiserService.getById(id);
      setFundraiser(data);
      setShowThankYouDialog(true);
    } catch (error) {
      console.error('Error updating fundraiser amount:', error);
    }
  };

  const handleDelete = async () => {
    try {
      // Store fundraiser info first
      const fundraiserInfo = {
        title: fundraiser.title,
        approved: fundraiser.approved,
        eventLink: window.location.href
      };
  
      // Set the info for our final dialog
      setDeletedFundraiserInfo(fundraiserInfo);
      
      // Close the confirm dialog
      setShowDeleteDialog(false);
  
      // If it was approved, send the email
      if (fundraiserInfo.approved) {
        try {
          await emailService.sendAdminDeleteNotice({
            fundraiserTitle: fundraiserInfo.title,
            eventLink: fundraiserInfo.eventLink,
            recipientEmail: 'info@hustlefundraise.com'
          });
        } catch (emailError) {
          console.error('Error sending admin delete notification:', emailError);
        }
      }
  
      // Delete the fundraiser
      await fundraiserService.delete(id);
      
      // Now show the final dialog
      setShowPostActionDialog(true);
  
    } catch (error) {
      console.error('Error in deletion process:', error);
      setError('Failed to delete fundraiser');
    }
  };
  

  const handleThankYouDialogClose = () => {
    setShowThankYouDialog(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        console.log('Fetching current user...');
        const user = await authService.getCurrentUser();
        console.log('Received user:', user);
        setCurrentUser(user);
      } catch (error) {
        console.error('Error fetching current user:', error);
        setCurrentUser(null);
      }
    };

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchFundraiser = async () => {
      try {
        setLoading(true);
        const data = await fundraiserService.getById(id);
        setFundraiser(data);
        
        if (currentUser && data.creator) {
          setIsCreator(data.creator._id === currentUser._id);
        }
      } catch (error) {
        console.error('Error fetching fundraiser:', error);
        setError('Failed to load fundraiser details');
      } finally {
        setLoading(false);
      }
    };

    fetchFundraiser();
  }, [id, currentUser]);

  const getYoutubeEmbedUrl = (url) => {
    if (!url) return null;
    const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|youtube\.com\/(?:embed\/|v\/))([^?&"'>]+))/);
    return match ? `https://www.youtube.com/embed/${match[1]}?autoplay=0&controls=1&showinfo=0&rel=0&modestbranding=1` : null;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <div className="text-xl text-white">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-red-900 text-white p-4 rounded-lg mb-4">{error}</div>
        <Link to="/fundraisers" className="text-blue-400 hover:text-blue-300">
          ← Back to fundraisers
        </Link>
      </div>
    );
  }

  if (!fundraiser) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4 text-white">Fundraiser not found</h2>
          <Link to="/fundraisers" className="text-blue-400 hover:text-blue-300">
            ← Back to fundraisers
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <Link 
          to="/fundraisers" 
          className="inline-flex items-center text-blue-400 hover:text-blue-300"
        >
          ← Back to fundraisers
        </Link>
        
        <div className="flex gap-2">
  <ShareButtons 
    title={fundraiser.title}
    url={window.location.href}
  />
{isAdmin ? (
  <Link
    to={`/fundraisers/${id}/edit`}
    className="inline-block bg-black text-white px-4 py-2 rounded border-2 border-white hover:bg-gray-900 transition-colors"
  >
    <span className="min-[360px]:inline hidden">Edit Fundraiser</span>
    <span className="min-[360px]:hidden inline">Edit</span>
  </Link>
) : isCreator && (
  <button
    onClick={() => setShowDeleteDialog(true)}
    className={`inline-block text-white px-4 py-2 rounded transition-colors ${
      !fundraiser.approved 
        ? "bg-yellow-500 hover:bg-yellow-600" 
        : "bg-red-500 hover:bg-red-600"
    }`}
  >
    <span className="min-[360px]:inline hidden">
      {!fundraiser.approved ? 'Cancel Fundraiser' : 'Delete Fundraiser'}
    </span>
    <span className="min-[360px]:hidden inline">
      {!fundraiser.approved ? 'Cancel' : 'Delete'}
    </span>
  </button>
)}
</div>
      </div>

      <Dialog open={showThankYouDialog} onOpenChange={handleThankYouDialogClose}>
        <DialogContent className="bg-black border-2 border-white">
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold text-white mb-4">
              Thank You for Your Donation!
            </DialogTitle>
            <div className="text-lg text-gray-300">
              <span className="block mb-4">
                Your donation of ${Number(donationAmount).toLocaleString()} has been successfully processed.
              </span>
              <span className="block mb-4">
                Your generosity helps make a difference for {fundraiser?.title}.
              </span>
              <div className="mt-6">
                <button
                  onClick={handleThankYouDialogClose}
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
                >
                  Close
                </button>
              </div>
            </div>
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <div className="bg-black rounded-lg shadow-lg overflow-hidden border-2 border-white">
        <div className="p-6">
          <div className="flex justify-between items-start mb-3">
            <div>
              <h1 className="text-3xl font-bold mb-2 text-white">{fundraiser.title}</h1>
              <p className="text-gray-300">
                Created by {fundraiser.creator?.username || 'Anonymous'} on{' '}
                {new Date(fundraiser.createdAt).toLocaleDateString()}
              </p>
              
              {fundraiser.tags && fundraiser.tags.length > 0 && (
                <div className="mt-2 flex flex-wrap gap-2">
                  {fundraiser.tags.map(tag => (
                    <span 
                      key={tag} 
                      className="px-2 py-1 bg-gray-800 text-white text-sm rounded"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="prose max-w-none mb-3">
            <p className="whitespace-pre-line text-white">{fundraiser.description}</p>
          </div>

          {fundraiser.images && fundraiser.images.length > 0 && (
            <>
              <Dialog>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                  {fundraiser.images.map((image, index) => (
                    <DialogTrigger key={index} asChild>
                      <img
                        src={getImageSrc(image)}
                        alt={`Fundraiser image ${index + 1}`}
                        className="rounded-lg w-full h-48 object-cover cursor-pointer hover:opacity-90 transition-opacity"
                      />
                    </DialogTrigger>
                  ))}
                </div>
                <DialogContent className="max-w-screen-lg w-full bg-black border-2 border-white">
                  <DialogHeader>
                    <DialogTitle className="text-center text-white">
                      {fundraiser.title} - Images
                    </DialogTitle>
                    <DialogDescription className="text-center text-gray-300">
                    </DialogDescription>
                  </DialogHeader>
                  <ImageCarousel images={fundraiser.images} />
                </DialogContent>
              </Dialog>
            </>
          )}

          {fundraiser.videoUrl && (
            <div className="mb-8">
              <div className="aspect-w-16 aspect-h-9">
                <iframe
                  src={getYoutubeEmbedUrl(fundraiser.videoUrl)}
                  title="Fundraiser video"
                  className="w-full h-[400px] rounded-lg"
                  allowFullScreen
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
          )}

          <div className="mb-8">
            <div className="flex justify-between mb-2">
              <span className="text-lg text-white">Progress</span>
              <span className="text-lg font-semibold text-white">
                ${(fundraiser.currentAmount || 0).toLocaleString()} of ${(fundraiser.goal || 0).toLocaleString()}
              </span>
            </div>
            <div className="w-full bg-gray-800 h-3">
              <div
                className="bg-green-500 h-3 transition-all duration-500"
                style={{
                  width: `${Math.min((fundraiser.currentAmount / fundraiser.goal) * 100, 100)}%`
                }}
              />
            </div>
            <div className="text-gray-300">
              {((fundraiser.currentAmount / fundraiser.goal) * 100).toFixed(0)}% raised
            </div>
            {fundraiser.endDate && (
              <div className="mt-2 text-sm text-gray-300">
                End Date: {new Date(fundraiser.endDate).toLocaleDateString()}
              </div>
            )}
          </div>

          {fundraiser.updates && fundraiser.updates.length > 0 && (
            <div className="mb-8">
              <h2 className="text-xl font-semibold mb-4 text-white">Updates</h2>
              <div className="space-y-4">
                {fundraiser.updates.map((update, index) => (
                  <div key={index} className="bg-gray-900 rounded-lg p-4">
                    <div className="text-sm text-gray-300 mb-2">
                      {new Date(update.date).toLocaleDateString()}
                    </div>
                    <p className="whitespace-pre-line text-white">{update.content}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {isEnded(fundraiser.endDate) ? (
            <div className="flex justify-center items-center py-8">
              <p className="text-xl text-white ">
                This Fundraiser has <span className="text-yellow-500">ended</span>
              </p>
            </div>
          ) : (
            <div className="flex justify-center">
              <Elements stripe={stripePromise}>
                <DonationForm
                  fundraiserId={id}
                  fundraiserTitle={fundraiser?.title || ''}
                  onSuccess={(amount) => handleDonationSuccess(amount, id, setFundraiser)}
                  onError={(errorMessage) => {
                    console.error('Donation failed:', errorMessage);
                  }}
                />
              </Elements>
            </div>
          )}

          {fundraiser.donations && fundraiser.donations.length > 0 && (
            <div className="mt-12">
              <h2 className="text-xl font-semibold mb-4 text-white">Recent Donations</h2>
              <div className="space-y-4">
                {fundraiser.donations.map(donation => (
                  <div key={donation._id} className="border-b border-gray-700 pb-4">
                    <div className="flex justify-between">
                      <span className="font-medium text-white">
                        {donation.isAnonymous ? 'Anonymous' : donation.donor?.username}
                      </span>
                      <span className="font-semibold text-white">${donation.amount.toLocaleString()}</span>
                    </div>
                    {donation.message && (
                      <p className="text-gray-300 mt-1">{donation.message}</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
  <DialogContent>
    <DialogHeader>
      <DialogTitle>
        {!fundraiser.approved ? 'Cancel Fundraiser' : 'Delete Fundraiser'}
      </DialogTitle>
      <DialogDescription>
        <div className='text-white'>
          Are you sure you want to {!fundraiser.approved ? 'cancel' : 'delete'} this fundraiser? This action cannot be undone.
        </div>
      </DialogDescription>
    </DialogHeader>
    <DialogFooter className="gap-2">
      <button
        type="button"
        onClick={() => setShowDeleteDialog(false)}
        className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-50 transition-colors text-white"
      >
        Close
      </button>
      <button
        type="button"
        onClick={handleDelete}
        className={`px-4 py-2 text-white rounded transition-colors ${
          !fundraiser.approved 
            ? "bg-yellow-500 hover:bg-yellow-600" 
            : "bg-red-500 hover:bg-red-600"
        }`}
      >
        {!fundraiser.approved ? 'Confirm' : 'Delete'}
      </button>
    </DialogFooter>
  </DialogContent>
</Dialog>

<Dialog open={showPostActionDialog}>
  <DialogContent>
    <DialogHeader>
      <DialogTitle className="text-white mb-4">
        {deletedFundraiserInfo?.approved ? 'Fundraiser Deleted' : 'Fundraiser Canceled'}
      </DialogTitle>
      <DialogDescription>
        <div className="text-white">
          {!deletedFundraiserInfo?.approved ? (
            'Your fundraiser has been canceled. Hope to see you back again soon!'
          ) : (
            <>
              Your fundraiser has been deleted. Please contact the administrator at{' '}
              <a 
                href="mailto:info@hustlefundraise.com"
                className="text-blue-400 hover:text-blue-300 underline"
              >
                info@hustlefundraise.com
              </a>
              {' '}with any questions or concerns. Thank you!
            </>
          )}
        </div>
      </DialogDescription>
    </DialogHeader>
    <DialogFooter>
      <button
        onClick={() => navigate('/dashboard')}
        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
      >
        Close
      </button>
    </DialogFooter>
  </DialogContent>
</Dialog>
    </div>
  );
}



export default FundraiserDetails;