// api.js
import axios from 'axios';

const isProduction = !process.env?.REACT_APP_API_URL;
const BASE_URL = isProduction ? '' : process.env?.REACT_APP_API_URL;

// const API_URL = '/api';  // Use relative path since it's served from same origin
// const AUTH_URL = '/auth'; // Use relative path

const API_URL = `${BASE_URL}/api`;
const AUTH_URL = `${BASE_URL}/auth`;

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Updated response interceptor with more robust auth handlingF
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Only redirect if not already on login page and not checking auth status
      const isAuthCheck = error.config.url.includes('/auth/status') || 
                         error.config.url.includes('/users/me');
      const isLoginPage = window.location.pathname === '/login';
      
      if (!isAuthCheck && !isLoginPage) {
        // Save the current path before redirecting
        const currentPath = window.location.pathname;
        if (currentPath !== '/login') {
          localStorage.setItem('authReturnUrl', currentPath);
        }
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export const authService = {
  login: () => {
    const returnUrl = localStorage.getItem('authReturnUrl') || '/dashboard';
    localStorage.removeItem('authReturnUrl'); // Clean up after getting the URL
    window.location.href = `${AUTH_URL}/google`;
  },

  logout: async () => {
    try {
      await axios.get(`${AUTH_URL}/logout`, { 
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      // Clear any stored auth data
      localStorage.removeItem('authReturnUrl');
      window.location.href = '/login';
    } catch (error) {
      console.error('Logout error:', error);
      window.location.href = '/login';
    }
  },

  getCurrentUser: async () => {
    try {
      const response = await api.get('/users/me');
      return response.data;
    } catch (error) {
      console.error('Get current user error:', error);
      return null;
    }
  },

  hubspotLogin: () => {
    window.location.href = `${AUTH_URL}/hubspot`; // Redirect to your backend's HubSpot auth route
  },

  checkAuth: async () => {
    try {
      const statusResponse = await api.get('/auth/status');
      if (statusResponse.data.isAuthenticated) {
        return {
          isAuthenticated: true,
          user: statusResponse.data.user
        };
      }
  
      const user = await authService.getCurrentUser();
      return {
        isAuthenticated: !!user,
        user
      };
    } catch (error) {
      console.error('Auth check error:', error);
      return { isAuthenticated: false, user: null };
    }
  },

  adminLogin: async (email, password) => {
    try {
      const response = await api.post('/auth/admin/login', { email, password });
      if (response.data.success) {
        localStorage.setItem('adminEmail', email);
      }
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Admin login failed');
    }
  },

  adminLogout: async () => {
    localStorage.removeItem('adminEmail');
    window.location.href = '/admin';
  }

};

export const fundraiserService = {
  getAll: async (params = {}) => {
    try {
      const response = await api.get('/fundraisers');
      console.log('GetAll Response:', response.data);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error in getAll:', error);
      return [];
    }
  },

  create: async (fundraiserData) => {
    try {
      const response = await api.post('/fundraisers', fundraiserData);
      return response.data;
    } catch (error) {
      if (error.response?.data?.error) {
        throw new Error(error.response.data.error);
      }
      throw error;
    }
  },

  getById: async (id) => {
    try {
      const response = await api.get(`/fundraisers/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error in getById:', error);
      throw error;
    }
  },

  getMine: async (a) => {
    try {
      console.log(a, '<-- caller')
      const response = await api.get('/users/my-fundraisers');
      console.log('GetMine Response:', response.data);
      return response.data?.fundraisers ? response.data?.fundraisers : [];
    } catch (error) {
      console.error('Error in getMine:', error);
      return [];
    }
  },

  updateFundraiser: async (id, data) => {
    try {
      const response = await api.put(`/fundraisers/${id}`, data);
      return response.data;
    } catch (error) {
      console.error('Error in update:', error);
      throw error;
    }
  },

 updateFundraiserAmount: async (id, amount) => {
    try {
      // Updated to use the correct endpoint path
      const response = await api.patch(`/fundraisers/${id}/amount`, {
        amount: Number(amount) // Ensure amount is a number
      });
      return response.data;
    } catch (error) {
      console.error('Error updating fundraiser amount:', error);
      throw error;
    }
  },

  delete: async (id) => {
    try {
      const response = await api.delete(`/fundraisers/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error in delete:', error);
      throw error;
    }
  }
};

export const donationService = {
  create: async (donationData) => {
    try {
      const response = await api.post('/donations', donationData);
      return response.data;
    } catch (error) {
      console.error('Error in donation create:', error);
      throw error;
    }
  },

  getByFundraiser: async (fundraiserId) => {
    try {
      const response = await api.get(`/donations/fundraiser/${fundraiserId}`);
      return response.data;
    } catch (error) {
      console.error('Error in getByFundraiser:', error);
      throw error;
    }
  },

  getMine: async () => {
    try {
      const response = await api.get('/donations/my-donations');
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error in getMine donations:', error);
      return [];
    }
  },
  createPaymentIntent: async ({ amount, fundraiserId, message }) => {
    try {
      const response = await api.post('/stripe/create-payment-intent', {
        amount: parseFloat(amount),
        fundraiserId,
        message
      });
      return response.data;
    } catch (error) {
      console.error('Error creating payment intent:', error);
      throw error;
    }
  }
};

export const userService = {
  getProfile: async () => {
    try {
      const response = await api.get('/users/me');
      return response.data;
    } catch (error) {
      console.error('Error in getProfile:', error);
      throw error;
    }
  },

  updateProfile: async (data) => {
    try {
      const response = await api.patch('/users/me', data);
      return response.data;
    } catch (error) {
      console.error('Error in updateProfile:', error);
      throw error;
    }
  },

  updateFundraiser: async (id, data) => {
    try {
      const response = await api.put(`/fundraisers/${id}`, data);
      return response.data;
    } catch (error) {
      console.error('Error updating fundraiser:', error);
      throw error;
    }
  },

  getMyFundraisers: async () => {
    try {
      const response = await api.get('/users/my-fundraisers');
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error in getMyFundraisers:', error);
      return [];
    }
  },

  getMyDonations: async () => {
    try {
      const response = await api.get('/users/my-donations');
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error in getMyDonations:', error);
      return [];
    }
  },

  getStats: async () => {
    try {
      const response = await api.get('/users/my-stats');
      return response.data;
    } catch (error) {
      console.error('Error in getStats:', error);
      throw error;
    }
  }
};

export const handleApiError = (error) => {
  if (error.response) {
    return error.response.data.error || 'An error occurred';
  } else if (error.request) {
    return 'No response from server';
  } else {
    return 'Error setting up request';
  }
};

// export const emailService = {
//   sendAdminEmail: async (emailData) => {
//     try {
//       const now = Date.now();
//       const RATE_LIMIT_MS = 10000; // 10 seconds
      
//       // Check if there's an active request in progress
//       const inProgress = localStorage.getItem('emailInProgress');
//       if (inProgress) {
//         console.log('Email send already in progress, dropping request');
//         return {
//           success: false,
//           rateLimited: true
//         };
//       }

//       // Check rate limit
//       const lastEmailSentTime = localStorage.getItem('lastEmailSentTime');
//       if (lastEmailSentTime) {
//         const timeSinceLastEmail = now - parseInt(lastEmailSentTime);
//         if (timeSinceLastEmail < RATE_LIMIT_MS) {
//           console.log(`Rate limiting: Ignoring email send, ${Math.ceil((RATE_LIMIT_MS - timeSinceLastEmail) / 1000)}s remaining`);
//           return { 
//             success: false, 
//             rateLimited: true 
//           };
//         }
//       }

//       try {
//         // Set in-progress flag
//         localStorage.setItem('emailInProgress', 'true');
        
//         const response = await api.post('/send-admin-email', emailData, {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
        
//         // Update the last sent time in localStorage
//         localStorage.setItem('lastEmailSentTime', now.toString());
        
//         return {
//           success: true,
//           data: response.data
//         };

//       } finally {
//         // Always clean up the in-progress flag
//         localStorage.removeItem('emailInProgress');
//       }

//     } catch (error) {
//       console.error('Error sending email:', error);
//       return {
//         success: false,
//         error: error.response?.data?.message || 'Failed to send email'
//       };
//     }
//   },

//   sendUserEmail: async (emailData) => {
//     try {
//       const now = Date.now();
//       const RATE_LIMIT_MS = 10000; // 10 seconds
      
//       // Check if there's an active request in progress
//       const inProgress = localStorage.getItem('emailInProgress');
//       if (inProgress) {
//         console.log('Email send already in progress, dropping request');
//         return {
//           success: false,
//           rateLimited: true
//         };
//       }

//       // Check rate limit
//       const lastEmailSentTime = localStorage.getItem('lastEmailSentTime');
//       if (lastEmailSentTime) {
//         const timeSinceLastEmail = now - parseInt(lastEmailSentTime);
//         if (timeSinceLastEmail < RATE_LIMIT_MS) {
//           console.log(`Rate limiting: Ignoring email send, ${Math.ceil((RATE_LIMIT_MS - timeSinceLastEmail) / 1000)}s remaining`);
//           return { 
//             success: false, 
//             rateLimited: true 
//           };
//         }
//       }

//       try {
//         // Set in-progress flag
//         localStorage.setItem('emailInProgress', 'true');
        
//         const response = await api.post('/send-user-email', emailData, {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
        
//         // Update the last sent time in localStorage
//         localStorage.setItem('lastEmailSentTime', now.toString());
        
//         return {
//           success: true,
//           data: response.data
//         };

//       } finally {
//         // Always clean up the in-progress flag
//         localStorage.removeItem('emailInProgress');
//       }

//     } catch (error) {
//       console.error('Error sending email:', error);
//       return {
//         success: false,
//         error: error.response?.data?.message || 'Failed to send email'
//       };
//     }
//   },

//   sendUserApprovalEmail: async (emailData) => {
//     try {
//       const now = Date.now();
//       const RATE_LIMIT_MS = 10000; // 10 seconds
      
//       // Check if there's an active request in progress
//       const inProgress = localStorage.getItem('emailInProgress');
//       if (inProgress) {
//         console.log('Email send already in progress, dropping request');
//         return {
//           success: false,
//           rateLimited: true
//         };
//       }

//       // Check rate limit
//       const lastEmailSentTime = localStorage.getItem('lastEmailSentTime');
//       if (lastEmailSentTime) {
//         const timeSinceLastEmail = now - parseInt(lastEmailSentTime);
//         if (timeSinceLastEmail < RATE_LIMIT_MS) {
//           console.log(`Rate limiting: Ignoring email send, ${Math.ceil((RATE_LIMIT_MS - timeSinceLastEmail) / 1000)}s remaining`);
//           return { 
//             success: false, 
//             rateLimited: true 
//           };
//         }
//       }

//       try {
//         // Set in-progress flag
//         localStorage.setItem('emailInProgress', 'true');
        
//         const response = await api.post('/send-user-approval-email', emailData, {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });
        
//         // Update the last sent time in localStorage
//         localStorage.setItem('lastEmailSentTime', now.toString());
        
//         return {
//           success: true,
//           data: response.data
//         };

//       } finally {
//         // Always clean up the in-progress flag
//         localStorage.removeItem('emailInProgress');
//       }

//     } catch (error) {
//       console.error('Error sending email:', error);
//       return {
//         success: false,
//         error: error.response?.data?.message || 'Failed to send email'
//       };
//     }
//   },
  
//   sendEmailTest: async (data) => {
//     console.log('service data', data);
//   }
// };

export const emailService = {
  sendAdminEmail: async (emailData) => {
    try {
      const now = Date.now();
      const RATE_LIMIT_MS = 10000; // 10 seconds
      
      // Use admin-specific keys
      const inProgress = localStorage.getItem('adminEmailInProgress');
      if (inProgress) {
        console.log('Admin email send already in progress');
        return {
          success: false,
          rateLimited: true
        };
      }

      const lastEmailSentTime = localStorage.getItem('lastAdminEmailSentTime');
      if (lastEmailSentTime) {
        const timeSinceLastEmail = now - parseInt(lastEmailSentTime);
        if (timeSinceLastEmail < RATE_LIMIT_MS) {
          console.log(`Rate limiting: Ignoring admin email send, ${Math.ceil((RATE_LIMIT_MS - timeSinceLastEmail) / 1000)}s remaining`);
          return { 
            success: false, 
            rateLimited: true 
          };
        }
      }

      try {
        localStorage.setItem('adminEmailInProgress', 'true');
        
        const response = await api.post('/send-admin-email', emailData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        localStorage.setItem('lastAdminEmailSentTime', now.toString());
        
        return {
          success: true,
          data: response.data
        };

      } finally {
        localStorage.removeItem('adminEmailInProgress');
      }

    } catch (error) {
      console.error('Error sending admin email:', error);
      return {
        success: false,
        error: error.response?.data?.message || 'Failed to send email'
      };
    }
  },

  sendUserEmail: async (emailData) => {
    try {
      const now = Date.now();
      const RATE_LIMIT_MS = 10000; // 10 seconds
      
      // Use user-specific keys
      const inProgress = localStorage.getItem('userEmailInProgress');
      if (inProgress) {
        console.log('User email send already in progress');
        return {
          success: false,
          rateLimited: true
        };
      }

      const lastEmailSentTime = localStorage.getItem('lastUserEmailSentTime');
      if (lastEmailSentTime) {
        const timeSinceLastEmail = now - parseInt(lastEmailSentTime);
        if (timeSinceLastEmail < RATE_LIMIT_MS) {
          console.log(`Rate limiting: Ignoring user email send, ${Math.ceil((RATE_LIMIT_MS - timeSinceLastEmail) / 1000)}s remaining`);
          return { 
            success: false, 
            rateLimited: true 
          };
        }
      }

      try {
        localStorage.setItem('userEmailInProgress', 'true');
        
        const response = await api.post('/send-user-email', emailData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        localStorage.setItem('lastUserEmailSentTime', now.toString());
        
        return {
          success: true,
          data: response.data
        };

      } finally {
        localStorage.removeItem('userEmailInProgress');
      }

    } catch (error) {
      console.error('Error sending user email:', error);
      return {
        success: false,
        error: error.response?.data?.message || 'Failed to send email'
      };
    }
  },

  // You can apply the same pattern to sendUserApprovalEmail if needed
  sendUserApprovalEmail: async (emailData) => {
    try {
      const now = Date.now();
      const RATE_LIMIT_MS = 10000; // 10 seconds
      
      const inProgress = localStorage.getItem('approvalEmailInProgress');
      if (inProgress) {
        console.log('Approval email send already in progress');
        return {
          success: false,
          rateLimited: true
        };
      }

      const lastEmailSentTime = localStorage.getItem('lastApprovalEmailSentTime');
      if (lastEmailSentTime) {
        const timeSinceLastEmail = now - parseInt(lastEmailSentTime);
        if (timeSinceLastEmail < RATE_LIMIT_MS) {
          console.log(`Rate limiting: Ignoring approval email send, ${Math.ceil((RATE_LIMIT_MS - timeSinceLastEmail) / 1000)}s remaining`);
          return { 
            success: false, 
            rateLimited: true 
          };
        }
      }

      try {
        localStorage.setItem('approvalEmailInProgress', 'true');
        
        const response = await api.post('/send-user-approval-email', emailData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        localStorage.setItem('lastApprovalEmailSentTime', now.toString());
        
        return {
          success: true,
          data: response.data
        };

      } finally {
        localStorage.removeItem('approvalEmailInProgress');
      }

    } catch (error) {
      console.error('Error sending approval email:', error);
      return {
        success: false,
        error: error.response?.data?.message || 'Failed to send email'
      };
    }
  },

  sendAdminDeleteNotice: async (emailData) => {
    try {
      const now = Date.now();
      const RATE_LIMIT_MS = 10000; // 10 seconds
      
      const inProgress = localStorage.getItem('adminDeleteNoticeInProgress');
      if (inProgress) {
        console.log('Approval email send already in progress');
        return {
          success: false,
          rateLimited: true
        };
      }

      const lastEmailSentTime = localStorage.getItem('adminDeleteNotice');
      if (lastEmailSentTime) {
        const timeSinceLastEmail = now - parseInt(lastEmailSentTime);
        if (timeSinceLastEmail < RATE_LIMIT_MS) {
          console.log(`Rate limiting: Ignoring approval email send, ${Math.ceil((RATE_LIMIT_MS - timeSinceLastEmail) / 1000)}s remaining`);
          return { 
            success: false, 
            rateLimited: true 
          };
        }
      }

      try {
        localStorage.setItem('adminDeleteNoticeInProgress', 'true');
        
        const response = await api.post('/send-admin-delete-notice', emailData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        localStorage.setItem('adminDeleteNotice', now.toString());
        
        return {
          success: true,
          data: response.data
        };

      } finally {
        localStorage.removeItem('approvalEmailInProgress');
      }

    } catch (error) {
      console.error('Error sending approval email:', error);
      return {
        success: false,
        error: error.response?.data?.message || 'Failed to send email'
      };
    }
  }
};

export default api;