import { Link } from 'react-router-dom';
import { FaInstagram, FaFacebook, FaLinkedin, FaTwitter, FaEnvelope } from 'react-icons/fa';
import logo from '../assets/hustle_logo_transparent-inverted.png';
import { useEffect, useState } from 'react'
import { emailService } from '../services/api';
import { useAdmin } from '../contexts/AdminContext';

function Home() {

  const { isAdmin } = useAdmin();

  console.log(isAdmin, 'isAdmin')
  
  useEffect(() => {
    // Parameters to test the email service
    const emailData = {
      // recipientEmail: 'mtmusicandart@gmail.com',
      recipientEmail: 'mtmusicandart@gmail.com',
      fundraiserTitle: `Sigma Chi Clash Against Cancer`,
      eventLink: 'https://hustlefundraise.com/fundraisers/6739563e0dc5cb71207dc33e',
    };

  

    const sendTestEmail = async () => {
      try {
        const response = await emailService.sendEmail(emailData);
        console.log('Test email sent:', response);  // Log the response for debugging
      } catch (error) {
        console.error('Error sending test email:', error);  // Log any error
      }
    };

     // sendTestEmail();
  }, []);  

  return (
    <div className="fixed inset-0 top-[67px] w-full overflow-hidden">
      {/* Video Background */}
      <div className="absolute inset-0">
        <iframe
          src="https://www.youtube.com/embed/oNZknCe2mu8?autoplay=1&mute=1&loop=1&playlist=oNZknCe2mu8&controls=0&playsinline=1&rel=0&modestbranding=1"
          className="w-screen h-screen object-cover pointer-events-none"
          allow="autoplay"
          frameBorder="0"
          title="background video"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) scale(1.5)',
            width: '100vw',
            height: '100vh',
            border: 'none',
          }}
        />
      </div>
      {/* Semi-transparent Overlay */}
      <div className="absolute inset-0 bg-black/80"></div>
      {/* Content */}
      <div className="absolute inset-0 z-10 flex flex-col items-center justify-center max-w-4xl mx-auto text-center text-white px-4">
      <img src={logo} className="w-[90%] 
             sm:mb-12 sm:max-w-[350px] 
             md:mb-16 md:max-w-[450px] 
             lg:mb-20 lg:max-w-[800px]"
            alt="logo"
          />
          
        {/* Buttons */}
        <div className="flex flex-col gap-4 justify-center items-center w-full px-4 
                        absolute bottom-20
                        sm:flex-row sm:w-[80%] sm:bottom-12
                        md:w-[600px] md:bottom-16
                        lg:bottom-20">
          <Link
            to="/fundraisers"
            className="trajan bg-[#28923C] text-black px-6 py-3 rounded-md hover:bg-blue-700 w-full 
                       sm:w-auto sm:flex-1 
                       whitespace-nowrap"
          >
            Browse Fundraisers
          </Link>
          <Link
            to="/fundraisers/create"
            className="trajan bg-black border-2 border-white text-white px-6 py-3 rounded-md hover:bg-gray-800 w-full 
                       sm:w-auto sm:flex-1"
          >
            Start Fundraising
          </Link>
        </div>
        {/* Social Media Icons */}
        <div className="absolute bottom-4 flex gap-6 text-white text-opacity-60">
          
          <a  href="https://www.instagram.com/tonyrobbins/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
            className="hover:text-opacity-100"
          >
            <FaInstagram size={24} />
          </a>
          
          <a  href="https://www.facebook.com/awspublicsector"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
            className="hover:text-opacity-100"
          >
            <FaFacebook size={24} />
          </a>
          
          <a  href="https://www.linkedin.com/in/officialtonyrobbins/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
            className="hover:text-opacity-100"
          >
            <FaLinkedin size={24} />
          </a>
          
          <a  href="https://x.com/AWS_Nonprofits"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="X (Twitter)"
            className="hover:text-opacity-100"
          >
            <FaTwitter size={24} />
          </a>
          <a  href="mailto:info@hustlefundraise.com"
            aria-label="Email"
            className="hover:text-opacity-100"
          > 
            <FaEnvelope size={24} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;